import request from 'util/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';

export interface IParamsGetMetaDataHotel {
  hotelId: number;
  page: number;
  limit: number;
  status: number;
  keywordId: number;
  name: string;
  identifier: string;
  supplierIds: number[];
}

export interface IMetaDataHotel {
  key: number;
  hotelId: number;
  name: string;
  price: number;
  link: string;
  supplierId: number;
  identifier: string;
  id: number | string;
}

export interface IPutMetaDataHotel {
  data: {
    id: number;
    hotelId: number | null;
    status: number;
  };
}

export interface IHotelDataMapping {
  key: number;
  id: number;
  supplierId: number;
  identifier: string;
  name: string;
  link: string;
  address: string;
  country: string | null;
  province: string | null;
}

const initialState = {
  metaData: {
    data: [] as IMetaDataHotel[],
    loading: false,
    description: {
      page: 1,
      limit: 1,
      total: 0,
    },
  },

  recommendHotels: {
    loading: false,
    data: [] as IHotelDataMapping[],
  },
  listHotelMapped: [],
  cache: {
    id: -9999,
    identifier: undefined,
    name: undefined,
    supplierId: -1
  },
  statusMappingHotel: 'idle',
};

export const actionGetMetaDataHotel = createAsyncThunk(
  'metaDataHotel/actionGetMetaDataHotel',
  async (params: IParamsGetMetaDataHotel, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'hotel-meta/',
        method: 'GET',
        params,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const actionGetByHotelId = createAsyncThunk(
  'metaDataHotel/actionGetByHotelId',
  async (hotelId: number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `hotel-meta/hotel-id/${hotelId}`,
        method: 'GET',
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const actionGetListHotelRecommend = createAsyncThunk(
  'metaDataHotel/actionGetListHotelRecommend',
  async (params: { hotelName: string }, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'hotel/recommend',
        method: 'GET',
        params,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const actionPutHotel = createAsyncThunk(
  'metaDataHotel/actionPutDataHotelMapping',
  async (params: IPutMetaDataHotel, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'hotel-meta/hotel-id',
        method: 'PUT',
        data: {
          ...params,
        },
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const slice = createSlice({
  name: 'metaDataHotel',
  initialState,
  reducers: {
    actionResetMapping: (state) => {
      state.recommendHotels.data = [];
    },
    actionChangeCache: (state, action) => {
      state.cache = action.payload;
    },
    actionResetMappingHotel: (state) => {
      state.statusMappingHotel = 'idle';
    },
    actionResetListHotelMapped: (state) => {
      state.listHotelMapped = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionGetMetaDataHotel.pending, (state) => {
        state.metaData.loading = true;
      })
      .addCase(actionGetMetaDataHotel.fulfilled, (state, action) => {
        state.metaData.loading = false;
        const { data, meta } = action.payload.data;
        state.metaData.data = data;
        state.metaData.description = meta;
      })
      .addCase(actionGetListHotelRecommend.pending, (state) => {
        state.recommendHotels.loading = true;
      })
      .addCase(actionGetListHotelRecommend.fulfilled, (state, action) => {
        state.recommendHotels.loading = false;
        const resData = get(action, 'payload.data');
        state.recommendHotels.data = resData.recommendHotels;
      })
      .addCase(actionPutHotel.pending, (state) => {
        state.statusMappingHotel = 'loading';
      })
      .addCase(actionPutHotel.fulfilled, (state) => {
        state.statusMappingHotel = 'success';
      })
      .addCase(actionPutHotel.rejected, (state) => {
        state.statusMappingHotel = 'error';
      })
      .addCase(actionGetByHotelId.fulfilled, (state, action) => {
        const resData = get(action, 'payload');
        state.listHotelMapped = resData ?? []
      });
  },
});

export const { actionResetMapping, actionChangeCache, actionResetMappingHotel,actionResetListHotelMapped } = slice.actions;

export const selectMetaDataHotel = (state: RootState) => state.metaDataHotel;

export const selectStatusMappingHotel = (state: RootState) => state.metaDataHotel.statusMappingHotel;
export const selectListHotelMapped = (state: RootState) => state.metaDataHotel.listHotelMapped;
export default slice.reducer;
