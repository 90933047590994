export const SUPPLIER_NAME = [
  {
    id: 7,
    show: true,
    name: '프리비아',
    domain: 'hotel.priviatravel.com/',
  },
  {
    id: 8,
    show: true,
    name: '투어비스',
    domain: 'hotel.tourvis.com',
  },
  {
    id: 5,
    show: true,
    name: '네이버',
    domain: 'hotels.naver.com/',
  },
  {
    id: 11,
    show: true,
    name: '야놀자',
    domain: 'place-site.yanolja.com/places/',
  },
  {
    id: 9,
    name: '여기어때',
    show: true,
    domain: 'yeogi.com/',
  },
];

export const SITES = {
  dc: {
    id: 1,
    name: 'dc',
  },
  cms: {
    id: 2,
    name: 'cms',
  },
  supplier: {
    id: 3,
    name: 'supplier',
  },
  discountDetail: {
    id: 10001,
    name: 'detailDiscount',
  },
  detail: {
    id: 10002,
    name: 'detail',
  },
};

export const STATUS_MAPPED = [
  {
    name: 'NEW',
    value: 0,
  },
  {
    name: 'NO MAP',
    value: 2,
  },
  {
    name: 'MAPPED',
    value: 1,
  },
];

export const INTERNAL_SUPPLIERS_IDS = [7, 8];
export const SUPPLIERS_WITH_SITE_ID = [7, 8];
export const SUPPLIERS_WITH_DETAIL_PRICES = [7];

export const SUPPLIERS = {
  privia: {
    id: 7,
    name: '프리비아',
  },
  tourvis: {
    id: 8,
    name: '투어비스',
  },
  yeogi: {
    id: 9,
    name: '여기어때',
  },
  yanolja: {
    id: 11,
    name: '야놀자',
  },
  naver: {
    id: 5,
    name: '네이버',
  },
};

export const MAX_RANK_WITH_DETAIL_PRICE = 20;
