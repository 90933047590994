import { ReactNode } from 'react';

interface IWrapperLayoutContentProps {
  children: ReactNode;
}

function WrapperLayoutContent({ children }: IWrapperLayoutContentProps) {
  return <div className="px-[8px] h-[var(--app-page-content-h)] w-full  overflow-hidden">{children}</div>;
}

export default WrapperLayoutContent;
