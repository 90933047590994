import { SUPPLIERS } from 'constants/hotel';
import { renderCheckLowestPrice } from 'util/hotelResult';
import RenderDIff from 'app/components/RenderDiff';

const renderCheckEmpty = (txt: string) => (txt ? txt.toLocaleString() : '-');

const childrenColumnsOfSuppliers: { [key: string]: Array<any> } = {
  7: [
    {
      key: 8,
      title: '대표가격',
      dataIndex: `pricedetaildiscount${SUPPLIERS.privia.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckLowestPrice,
    },
    {
      key: 9,
      title: '가격',
      dataIndex: `pricedetail${SUPPLIERS.privia.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckLowestPrice,
    },
    {
      key: 2,
      title: 'DC',
      dataIndex: `pricedc${SUPPLIERS.privia.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckEmpty,
    },
    {
      title: 'DC 델타',
      dataIndex: 'diffDC',
      align: 'center',
      width: '4%',
      render: (txt: string) => <RenderDIff txt={txt} />,
    },
    {
      key: 3,
      title: 'CMS',
      dataIndex: `pricecms${SUPPLIERS.privia.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckEmpty,
    },
    {
      title: 'CMS 델타',
      dataIndex: 'diffCMS',
      align: 'center',
      width: '4%',
      render: (txt: string) => <RenderDIff txt={txt} />,
    },
    {
      key: 4,
      title: '공급사',
      dataIndex: `pricesupplier${SUPPLIERS.privia.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckEmpty,
    },
    {
      title: '공급사 델타',
      dataIndex: 'diffSup',
      align: 'center',
      width: '4%',
      render: (txt: string) => <RenderDIff txt={txt} />,
    },
  ],
  8: [
    {
      key: 2,
      title: 'DC',
      dataIndex: `pricedc${SUPPLIERS.tourvis.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckEmpty,
    },
    {
      title: 'DC 델타',
      dataIndex: 'diffDC',
      align: 'center',
      width: '4%',
      render: (txt: string) => <RenderDIff txt={txt} />,
    },
    {
      key: 3,
      title: 'CMS',
      dataIndex: `pricecms${SUPPLIERS.tourvis.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckEmpty,
    },
    {
      title: 'CMS 델타',
      dataIndex: 'diffCMS',
      align: 'center',
      width: '4%',
      render: (txt: string) => <RenderDIff txt={txt} />,
    },
    {
      key: 4,
      title: '공급사',
      dataIndex: `pricesupplier${SUPPLIERS.tourvis.name}`,
      align: 'center',
      width: '6%',
      render: renderCheckEmpty,
    },
    {
      title: '공급사 델타',
      dataIndex: 'diffSup',
      align: 'center',
      width: '4%',
      render: (txt: string) => <RenderDIff txt={txt} />,
    },
  ],
};

export default childrenColumnsOfSuppliers;