import { getHotelBaseUrl, getSupplierName, renderCheckEmpty } from 'util/hotel';
import { SUPPLIER_NAME, SUPPLIERS } from 'constants/hotel';
import { Button, Pagination, Table, Input, Space } from 'antd';
import { useMemo, useEffect, memo, useState, useCallback } from 'react';
import { LinkOutlined, SearchOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectMetaDataHotel,
  IMetaDataHotel,
  actionResetMapping,
  actionChangeCache,
  selectStatusMappingHotel,
  actionGetMetaDataHotel,
  actionResetMappingHotel,
  actionGetListHotelRecommend,
  IParamsGetMetaDataHotel,
} from 'store/metaDataHotelSlice';
import { actionGetHotelIsMapped, actionSetHotelIDIsMapped } from 'store/hotelSlice';
import { ColumnType } from 'antd/lib/table/interface';

interface TableMetaDataProps {
  status: number;
  keywordId: number;
  setHotelName: Function;
}

function TableMetaData({ status, keywordId, setHotelName }: TableMetaDataProps) {
  const [pageInfo, setPageInfo] = useState({
    currentNumber: 1,
    total: 0,
    pageSize: 100,
  });

  const { metaData, cache } = useAppSelector(selectMetaDataHotel);
  const dispatch = useAppDispatch();
  const statusMappingHotel = useAppSelector(selectStatusMappingHotel);

  const [searchHotelId, setSearchHotelId] = useState<number>();
  const [searchHotelName, setSearchHotelName] = useState<string>('');
  const [searchIdentifier, setSearchIdentifier] = useState<string>('');
  const [searchSupplierId, setSearchSupplierId] = useState<number[]>([]);

  type DataIndex = keyof IMetaDataHotel;

  const handleSearch = useCallback((selectedKeys: any, dataIndex: string) => {
    if (dataIndex === 'hotelId') setSearchHotelId(parseInt(selectedKeys[0], 10));
    if (dataIndex === 'name') setSearchHotelName(selectedKeys[0]);
    if (dataIndex === 'identifier') setSearchIdentifier(selectedKeys[0]);
    if (dataIndex === 'supplierId') setSearchSupplierId(selectedKeys);
    return true;
  }, []);

  const handleReset = useCallback(
    (clearFilters: () => void, selectedKeys: string[], dataIndex: DataIndex) => {
      clearFilters();
      handleSearch(selectedKeys as string[], dataIndex);
    },
    [handleSearch]
  );

  const handleChangePageNumber = (page: number, pageSize: number) => {
    setPageInfo((prev) => ({
      ...prev,
      currentNumber: page,
      pageSize,
    }));
  };

  const getColumnSearchProps = useCallback(
    (dataIndex: DataIndex): ColumnType<IMetaDataHotel> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            // value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, selectedKeys as string[], dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    }),
    [handleReset, handleSearch]
  );

  useEffect(() => {
    setPageInfo((prev) => ({
      ...prev,
      currentNumber: 1,
    }));
  }, [keywordId, pageInfo.pageSize, searchHotelId, searchHotelName, searchIdentifier, searchSupplierId, status]);

  useEffect(() => {
    dispatch(
      actionGetMetaDataHotel({
        hotelId: searchHotelId,
        name: searchHotelName,
        identifier: searchIdentifier,
        supplierIds: searchSupplierId,
        page: pageInfo.currentNumber,
        limit: pageInfo.pageSize,
        status,
        keywordId,
      } as IParamsGetMetaDataHotel)
    );
  }, [
    dispatch,
    keywordId,
    pageInfo.pageSize,
    pageInfo.currentNumber,
    searchHotelId,
    searchHotelName,
    searchIdentifier,
    searchSupplierId,
    status,
  ]);

  useEffect(() => {
    if (statusMappingHotel === 'success') {
      dispatch(
        actionGetMetaDataHotel({
          hotelId: NaN,
          page: pageInfo.currentNumber,
          limit: pageInfo.pageSize,
          status,
          keywordId,
          name: searchHotelName,
          identifier: searchIdentifier,
          supplierIds: searchSupplierId,
        })
      );
      dispatch(actionResetMappingHotel());
    }
  }, [
    dispatch,
    keywordId,
    pageInfo.currentNumber,
    pageInfo.pageSize,
    searchHotelName,
    searchIdentifier,
    searchSupplierId,
    status,
    statusMappingHotel,
  ]);

  useEffect(() => {
    setPageInfo((prev) => ({
      ...prev,
      total: metaData.description.total,
    }));
  }, [metaData.description.total]);

  useEffect(() => {
    const { name } = cache;
    if (name) {
      dispatch(
        actionGetListHotelRecommend({
          hotelName: name,
        })
      );
    }
  }, [dispatch, cache, metaData]);

  const handleClickRowTable = (record: IMetaDataHotel) => ({
    onClick: async () => {
      if (!record.hotelId) {
        setHotelName(record.name);
        dispatch(actionChangeCache({ id: record.id, identifier: record.identifier, name: record.name, supplierId: record.supplierId }));
        dispatch(actionSetHotelIDIsMapped(0));
      } else {
        dispatch(actionSetHotelIDIsMapped(record.hotelId));
        dispatch(actionGetHotelIsMapped({ hotelId: record.hotelId }));
        dispatch(actionChangeCache({ id: record.id }));
        dispatch(actionResetMapping());
      }
    },
  });

  const columns: ColumnsType<IMetaDataHotel> = useMemo(
    () => [
      {
        title: 'hotel ID',
        width: '8%',
        dataIndex: 'hotelId',
        align: 'center',
        key: 'hotelId',
        render: renderCheckEmpty,
        ...getColumnSearchProps('hotelId'),
      },
      {
        title: 'hotel name',
        width: '30%',
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        render: (txt: string) => <div className="!text-left px-[12px]">{renderCheckEmpty(txt)}</div>,
        ...getColumnSearchProps('name'),
      },
      {
        title: 'identifier',
        width: '26%',
        dataIndex: 'identifier',
        align: 'center',
        key: 'identifier',
        render: (txt: string) => <div className="!text-left px-[12px]">{renderCheckEmpty(txt)}</div>,
        ...getColumnSearchProps('identifier'),
      },
      {
        title: 'supplier',
        width: '14%',
        dataIndex: 'supplierId',
        align: 'center',
        key: 'supplier',
        render: getSupplierName,
        filters: SUPPLIER_NAME.map((item: any) => ({
          text: item.name,
          value: item.id,
        })),
      },
      {
        title: 'link',
        width: '8%',
        dataIndex: 'link',
        align: 'center',
        key: 'link',
        render: (link: string, record: IMetaDataHotel) => (
          <Button
            type="link"
            className="w-full h-full hover:bg-[#93c5fd] hover:text-white"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <a
              href={
                // eslint-disable-next-line no-nested-ternary
                record.supplierId !== SUPPLIERS.naver.id
                  ? (record.supplierId !== SUPPLIERS.yeogi.id ? `${getHotelBaseUrl(record.supplierId)}${link}`.replaceAll('com//', 'com/') : `${getHotelBaseUrl(record.supplierId)}domestic-accommodations/${record.identifier}`)
                  : `${getHotelBaseUrl(record.supplierId)}${link.replace('/item/photo', 'item')}`
              }
              aria-label="baseUrl"
              target="_blank"
              rel="noreferrer"
              key={`${link}${record.identifier}`}
            >
              <LinkOutlined className="text-[14px]" />
            </a>
          </Button>
        ),
      },
    ],
    [getColumnSearchProps]
  );

  return (
    <div className="h-full overflow-hidden rounded-[8px]">
      <Table
        rowKey="key"
        scroll={{ y: '80vh' }}
        columns={columns}
        className="custom-table-antd  table-mapping w-full h-[calc(100%_-_40px)] bg-[#FFFFFF]"
        rowClassName={(record: IMetaDataHotel) => (record.id === cache.id ? 'ant-table-row-selected' : '')}
        dataSource={metaData.data.map((item, index) => ({ ...item, key: index }))}
        loading={metaData.loading}
        bordered
        pagination={false}
        onRow={handleClickRowTable}
        onChange={(pagination: any, filters: any) => {
          handleSearch(filters.supplier, 'supplierId');
        }}
      />
      <div className="flex items-center justify-end bg-white rounded-b-[4px]">
        <Pagination
          className="m-2"
          size="small"
          current={pageInfo.currentNumber}
          total={pageInfo.total}
          onChange={handleChangePageNumber}
          pageSize={pageInfo.pageSize}
          pageSizeOptions={[100, 300]}
        />
      </div>
    </div>
  );
}

export default memo(TableMetaData);
