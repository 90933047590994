import request from 'util/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';

export interface IParamsGetDetail {
  page: number;
  limit: number;
  name: string;
  country: string;
  province: string;
}
export interface IRooms {
  supplierId: number;
  checkIn: string;
  checkOut: string;
  price: number;
}
const initialState = {
  items: [] as { price: number; name: string; rooms: IRooms[] }[],
  meta: [],
  loading: false,
};
export const actionGetHotelRoom = createAsyncThunk(
  'hotelRoom/actionGetHotelRoom',
  async (params: IParamsGetDetail, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/hotel',
        method: 'GET',
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'hotelRoom',
  initialState,
  reducers: {
    actionInitDataState(state) {
      state.items = initialState.items;
      state.meta = initialState.meta;
      state.loading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetHotelRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetHotelRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.items = get(action, 'payload.data');
        state.meta = get(action, 'payload.meta');
      });
  },
});

export const selectItems = (state: RootState) => state.hotelRoom.items;
export const selectMeta = (state: RootState) => state.hotelRoom.meta;

export const selectLoading = (state: RootState) => state.hotelRoom.loading;

export default slice.reducer;
