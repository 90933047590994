const URL = {
  Login: '/login',
  Meta: '/metadata-hotel',
  HotelResult: '/',
  HotelMappingResult: '/hotel-mapping-result',
  Hotel: '/hotel',
  Hotel_Internal: '/hotelinternal',
};

export default URL;
