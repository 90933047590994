import { Layout } from 'antd';
import AppFooter from 'app/components/AppFooter';
import Sidebar from 'app/layouts/Sidebar';

const { Content } = Layout;

interface DefaultLayoutProps {
  children: JSX.Element;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const { children } = props;
  return (
    <Layout className="h-screen">
      <Sidebar />
      <Layout>
        <Content>{children}</Content>
        <AppFooter />
      </Layout>
    </Layout>
  );
}
