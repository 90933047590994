import { Footer } from 'antd/lib/layout/layout';
import moment from 'moment';

function AppFooter() {
  const currentYear = moment().format('YYYY');
  return (
    <Footer className="h-[48px] bg-white">
      <div className="flex items-center justify-center h-full">
        Copyright {currentYear} <span className="uppercase font-semibold px-[2px]">tidesquare corporation .</span>
        All rights reserved.
      </div>
    </Footer>
  );
}

export default AppFooter;
