function HighlightText(props: any) {
  const { txt, searchString } = props;
  const indexSearchStr = txt.indexOf(searchString);
  if (indexSearchStr !== -1)
    return (
      <>
        {txt.substring(0, indexSearchStr)}
        <span className="highlight">{searchString}</span>
        {txt.substring(indexSearchStr).replace(searchString, '')}
      </>
    );
  return txt;
}

export default HighlightText;
