import { PageHeader, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface IViewData {
  name: string;
  masterId: number;
  isGetCalendar: string;
  isGetPrice: string;
}
function HotelInternal() {
  const columnsHotelInternal: ColumnsType<IViewData> = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      align: 'center',
    },
    {
      title: 'masterId',
      dataIndex: 'masterId',
      key: 'masterId',
      fixed: 'left',
      align: 'center',
    },
    {
      title: 'isGetCalendar',
      dataIndex: 'isGetCalendar',
      key: 'isGetCalendar',
      fixed: 'left',
      align: 'center',
    },
    {
      title: 'isGetPrice',
      dataIndex: 'isGetPrice',
      key: 'isGetPrice',
      fixed: 'left',
      align: 'center',
    },
  ];
  const datasource = [
    {
      name: 'Madiana Prince',
      masterId: 111,
      isGetCalendar: 'Y',
      isGetPrice: 'Y',
    },
    {
      name: 'Madiana Prince 2',
      masterId: 222,
      isGetCalendar: 'Y',
      isGetPrice: 'Y',
    },
    {
      name: 'Madiana Prince 3',
      masterId: 333,
      isGetCalendar: 'Y',
      isGetPrice: 'Y',
    },
    {
      name: 'Madiana Prince 4',
      masterId: 444,
      isGetCalendar: 'Y',
      isGetPrice: 'Y',
    },
    {
      name: 'Madiana Prince 5',
      masterId: 555,
      isGetCalendar: 'Y',
      isGetPrice: 'Y',
    },
  ];
  return (
    <>
      <PageHeader style={{ backgroundColor: 'white' }} backIcon={null} title="Hotel Internal" />
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <Table bordered columns={columnsHotelInternal} dataSource={datasource} size="large" pagination={false} />
      </Space>
    </>
  );
}

export default HotelInternal;
