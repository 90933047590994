import { renderCheckEmpty, renderCheckYorN } from 'util/hotel';
import { Table, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'store';
import { memo, useState } from 'react';
import { selectMetaDataHotel, IHotelDataMapping, actionPutHotel, selectListHotelMapped, actionGetByHotelId } from 'store/metaDataHotelSlice';
import HighlightText from 'app/components/HighlightText';
import { selectHotelIdIsMapped, selectHotelIsMapped } from 'store/hotelSlice';
import ModalMapping from './ModalMapping';

interface ITableMapping {
  hotelName: string;
}

function TableMapping({ hotelName }: ITableMapping) {
  const { recommendHotels, cache } = useAppSelector(selectMetaDataHotel);
  const hotelIsMapped = useAppSelector(selectHotelIsMapped);
  const hotelIdIsMapped = useAppSelector(selectHotelIdIsMapped);
  const [openModalMapping, setOpenModalMapping] = useState<boolean>(false)
  const listHotelMapped = useAppSelector(selectListHotelMapped)
  const dispatch = useAppDispatch();
  const mappingHotel = (record: IHotelDataMapping) => {
    if (hotelIdIsMapped > 0) {
      dispatch(
        actionPutHotel({
          data: {
            id: cache.id,
            hotelId: null,
            status: 2,
          },
        })
      );
    }
    dispatch(actionGetByHotelId(record.id)).then(res => {
      const resData = res.payload;
      const isExistHotelMapped = resData.some((item:any) => item.supplierId === cache.supplierId)
      if(isExistHotelMapped){
        setOpenModalMapping(true)
      } else {
        dispatch(
          actionPutHotel({
            data: {
              id: cache.id,
              hotelId: record.id,
              status: 1,
            },
          })
        );
      }
    })
  };

  const columnsMetaDataMapping: ColumnsType<IHotelDataMapping> = [
    {
      title: 'hotel ID',
      dataIndex: 'id',
      align: 'center',
      width: '8%',
    },
    {
      title: 'hotel name',
      dataIndex: 'name',
      align: 'center',
      width: '30%',
      render: (txt: string) => (
        <div className="!text-left px-[12px]">
          <HighlightText txt={txt.replaceAll(' ', '')} searchString={hotelName.replaceAll(' ', '')} />
        </div>
      ),
    },
    {
      title: 'address',
      dataIndex: 'address',
      align: 'center',
      width: '26%',
      render: (txt: string) => <div className="!text-left px-[12px]">{renderCheckEmpty(txt)}</div>,
    },
    {
      title: 'country',
      dataIndex: 'country',
      align: 'center',
      render: renderCheckEmpty,
      width: '8%',
    },
    {
      title: 'telNo',
      dataIndex: 'telNo',
      align: 'center',
      width: '8%',
      render: (txt: string) => <div className="!text-left px-[12px]">{renderCheckEmpty(txt)}</div>,
    },
    {
      title: 'homepage',
      dataIndex: 'hotelHomepage',
      align: 'center',
      width: '8%',
      render: renderCheckYorN,
    },
    {
      title: 'Action',
      align: 'center',
      width: '4%',
      render: (record) => (
        <Button
          className="uppercase w-full h-full hover:bg-[#93c5fd] hover:text-white font-semibold"
          type="link"
          onClick={() => mappingHotel(record)}
        >
          {hotelIdIsMapped > 0 ? 'UNMAP' : 'MAP'}
        </Button>
      ),
    },
  ];

  return (
    <>
    <Table
      columns={columnsMetaDataMapping}
      className="custom-table-antd w-full"
      dataSource={hotelIdIsMapped > 0 ? hotelIsMapped : recommendHotels.data.slice(0, 10)}
      bordered
      loading={recommendHotels.loading}
      pagination={false}
    />
    <ModalMapping openModal={openModalMapping} setOpenModal={setOpenModalMapping} infoMappingHotel={cache} />
    </>
  );
}

export default memo(TableMapping);
