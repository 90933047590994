import { SUPPLIER_NAME, SUPPLIERS } from 'constants/hotel';
import { Checkbox, Switch } from 'antd';
import { useEffect} from 'react';
import { cloneDeep} from "lodash";

interface GridGroupProps {
  setListSupplierName: Function;
  showGrid: boolean;
  listSupplierName: any;
  check: any;
  setCheck: any
}
const defaultSupplierName = cloneDeep(SUPPLIER_NAME)
function GridGroup({ listSupplierName, setListSupplierName, showGrid, check, setCheck }: GridGroupProps) {
  const handleChangeSupplierShow = (name: string) => {
    const newListSupplier = defaultSupplierName.map((item) => {
      if (item.name === name) {
        // eslint-disable-next-line no-param-reassign
        item.show = !item.show;
      }
      return item;
    });
    setListSupplierName(newListSupplier);
  };
  const onChange = (checked: boolean, button: any) => {
    if (button.target.id === 'privia') {
      setCheck({ privia: !check.privia, tourvis: false });
    }
    if (button.target.id === 'tourvis') {
      setCheck({ privia: false, tourvis: !check.tourvis });
    }
  };
  useEffect(() => {
    if (check.privia) {
      setListSupplierName(
        SUPPLIER_NAME.filter((item) => item.id === SUPPLIERS.privia.id || item.id === SUPPLIERS.naver.id)
      );
    } else if (check.tourvis) {
      setListSupplierName(
        SUPPLIER_NAME.filter((item) => item.id === SUPPLIERS.tourvis.id || item.id === SUPPLIERS.naver.id)
      );
    } else setListSupplierName(defaultSupplierName);
  }, [check, setListSupplierName]);


  return showGrid ? (
    <div className=" h-[50px] w-[640px] flex justify-between mb-1">
      {listSupplierName.map((item: any, index: number) => (
        <div className="w-[50px] inline-flex mr-3 justify-center items-center" key={index}>
          <div className="flex-rol ">
            <div className="flex justify-center items-center ">
              <Checkbox className="ml-1" checked={item.show} onChange={() => handleChangeSupplierShow(item.name)} />
            </div>
            <span>{item.name.toUpperCase()}</span>
          </div>
        </div>
      ))}
      <div className="flex flex-col">
        <div>
          <Switch
            size="small"
            id="privia"
            checked={check.privia}
            onChange={(checked, button) => onChange(checked, button)}
          />
          <span> Privia Mode </span>
        </div>
        <div>
          <Switch
            size="small"
            id="tourvis"
            checked={check.tourvis}
            onChange={(checked, button) => onChange(checked, button)}
          />
          <span> Tourvis Mode </span>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}

export default GridGroup;
