import URL from 'constants/url';
import { Layout, Menu, MenuProps } from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatabaseOutlined } from '@ant-design/icons';

import logoImage from 'app/images/logo.png';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  // getItem('Hotel', URL.Hotel, <DatabaseOutlined />),
  getItem('Hotel result', URL.HotelResult, <DatabaseOutlined />),
  getItem('Metadata Hotel', URL.Meta, <DatabaseOutlined />),
];

function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  function handleSelectItem(data: { key: string }) {
    navigate(data.key);
  }
  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div role="button" aria-label="logo" onMouseDown={handleLogoClick} tabIndex={0} style={{ height: 32, margin: 16 }}>
        <img src={logoImage} alt="" width="100%" />
      </div>
      <Menu
        className="min-h-screen"
        theme="dark"
        mode="inline"
        items={items}
        defaultSelectedKeys={[pathname]}
        onSelect={handleSelectItem}
      />
    </Sider>
  );
}

export default Sidebar;
