export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY/MM/DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MINUTE_FORMAT = 'HH:mm';
export const ERROR_MESSAGE = 'Error';
export const LIMIT_PAGING = 20;
export const SupplierPrice = [
  'price네이버',
  'price프리비아',
  'price투어비스',
  'price여기어때',
  'price인터파크',
  'price야놀자',
];
