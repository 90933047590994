import request from 'util/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';
import { IHotelDataMapping } from 'store/metaDataHotelSlice';

interface IParamsGetHotelIsMapped {
  hotelId: number;
}
export interface IHotelLinkByKeyword {
  checkinDate: string;
  checkoutDate: string;
  keywordId: number;
}

interface IInitial {
  data: any[];
  loading: boolean;
  hotelMapped: IHotelDataMapping[];
  hotelId: number;
  task: any[];
}

const initialState: IInitial = {
  data: [],
  loading: false,
  hotelMapped: [],
  hotelId: 0,
  task: [],
};
export const actionGetHotel = createAsyncThunk('hotel/actionGetHotel', async (_, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/hotel/hotel-provinces',
      method: 'GET',
    });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const actionGetHotelIsMapped = createAsyncThunk(
  'hotel/actionGetHotelIsMapped',
  async (params: IParamsGetHotelIsMapped, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/hotel/${params.hotelId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    actionInitDataState(state) {
      state.data = initialState.data;
      state.loading = false;
    },
    actionSetHotelIDIsMapped(state, action) {
      state.hotelId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetHotel.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetHotel.fulfilled, (state, action) => {
        state.loading = false;
        state.data = get(action, 'payload.data');
      })
      .addCase(actionGetHotelIsMapped.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetHotelIsMapped.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelMapped = get(action, 'payload.data');
      })
  },
});

export const { actionSetHotelIDIsMapped } = slice.actions;
export const selectHotelIdIsMapped = (state: RootState) => state.hotel.hotelId;

export const selectHotelData = (state: RootState) => state.hotel.data;
export const selectHotelIsMapped = (state: RootState) => state.hotel.hotelMapped;
export const selectHotelLinkByKeyword = (state: RootState) => state.hotel.task;
export const selectHotelLoading = (state: RootState) => state.hotel.loading;

export default slice.reducer;
