/* eslint-disable import/extensions */
import { memo, useEffect, useState } from 'react';
import WrapperLayoutContent from 'app/components/WrapperLayoutContent';
import { Button, Input, Segmented, Select } from 'antd';
import { actionGetKeyword, selectKeyword } from 'store/hotelResultSlice';
import { actionGetListHotelRecommend, actionPutHotel, selectMetaDataHotel } from 'store/metaDataHotelSlice';
import { useAppDispatch, useAppSelector } from 'store';
import TableMapping from './TableMapping';
import TableMetaData from './TableMetaData';
import { STATUS_MAPPED } from '../../../constants/hotel';

function MetaData() {
  const { cache } = useAppSelector(selectMetaDataHotel);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(0);
  const [keywordId, setKeywordId] = useState<number>(1);
  const keywords = useAppSelector(selectKeyword);
  const [hotelName, setHotelName] = useState<string>('');

  const handleChangeKeyword = (value: any) => {
    setKeywordId(value);
  };
  const noMapHotel = () => {
    dispatch(
      actionPutHotel({
        data: {
          id: cache.id,
          hotelId: null,
          status: 2,
        },
      })
    );
  };

  useEffect(() => {
    if (keywords.length) {
      setKeywordId((keywords[0] as any).id);
    }
  }, [keywords]);

  useEffect(() => {
    dispatch(actionGetKeyword());
  }, [dispatch]);

  return (
    <WrapperLayoutContent>
      <div className="flex h-[68px] items-center ">
        <div className="w-[50%] grow">
          <Select
            className="w-[120px] custom-select-meta-data ml-2"
            size="large"
            value={keywordId}
            onChange={handleChangeKeyword}
            allowClear
            showSearch
            options={keywords.map((kw: any) => ({
              label: kw.keyword,
              value: kw.id,
            }))}
            placeholder="Choose keyword"
          />
          <Segmented
            className="custom-segmented ml-2"
            options={STATUS_MAPPED.map((item) => {
              const { name, value } = item;
              return {
                label: (
                  <div
                    className="px-[8px] py-[4px] w-[84px] uppercase"
                    aria-hidden="true"
                    onClick={() => setStatus(value)}
                  >
                    {name}
                  </div>
                ),
                value,
              };
            })}
            defaultValue={STATUS_MAPPED[0].value}
          />
        </div>
        <div className="w-[50%] grow">
          <Input
            className="w-[250px] h-[40px] ml-2"
            value={hotelName}
            onChange={(e) => {
              setHotelName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(
                  actionGetListHotelRecommend({
                    hotelName,
                  })
                );
              }
            }}
          />
          <Button className="float-right mr-2 px-[8px] py-[4px] h-[40px]" type="primary" onClick={() => noMapHotel()}>
            NO MAP
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-center w-full  overflow-x-auto overflow-hidden ">
        <div className="w-full grid grid-cols-2 gap-3 h-full">
          <TableMetaData status={status} keywordId={keywordId} setHotelName={setHotelName} />
          <TableMapping hotelName={hotelName} />
        </div>
      </div>
    </WrapperLayoutContent>
  );
}

export default memo(MetaData);
