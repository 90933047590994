import request from 'util/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { get } from 'lodash';
import { IHotelInSupplierByName } from 'app/pages/HotelResult';

export interface IParamsGetHotelInSupplierByName {
  supplierId: number | string;
  name: string | undefined;
}

export interface IHotelIsMap {
  hotelId: number;
  children: [];
}

const initialState = {
  mappingResult: {
    data: [] as IHotelIsMap[],
    loading: false,
    description: {
      total: 0,
      page: 0,
      limit: 1,
    },
  },
  modalData: {
    title: '',
    data: [] as IHotelInSupplierByName[],
    loading: false,
  },
};


export const actionGetHotelInSupplierByName = createAsyncThunk(
  'hotelMappingResult/actionGetHotelInSupplierByName',
  async (params: IParamsGetHotelInSupplierByName, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'hotel-meta/recommend',
        method: 'GET',
        params,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const slice = createSlice({
  name: 'hotelMappingResult',
  initialState,
  reducers: {
    actionUpdateModalDataTitle: (state, action) => {
      const { name, hotelId } = action.payload;
      state.modalData.title = `${hotelId}_${name}`;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetHotelInSupplierByName.pending, (state) => {
        state.modalData.loading = true;
      })
      .addCase(actionGetHotelInSupplierByName.fulfilled, (state, action) => {
        state.modalData.loading = false;
        state.modalData.data = get(action, 'payload.data');
      });
  },
});

export const selectMappingResult = (state: RootState) => state.hotelMappingResultSlice;
export const { actionUpdateModalDataTitle } = slice.actions;
export default slice.reducer;
