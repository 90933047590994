import request from 'util/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';

export interface IParamsGetTask {
  page: number;
  limit: number;
}

export interface IParamsPostTask {
  from: string;
  to: string;
  airline: string;
  name: string;
  nameEn?: string;
  seatClass?: number;
  routeType: number;
}

interface ITask {
  id: number;
  from: string;
  to: string;
  key: number;
  airline: string;
  name: string;
  nameEn: string;
  seatClass: number;
}

export interface IInitStateTasks {
  tasks: ITask[];
}

const initialState: IInitStateTasks = {
  tasks: [],
};

export const actionGetTasks = createAsyncThunk(
  'hotel/actionGetTasks',
  async (params: IParamsGetTask, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/hotel',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionPostTask = createAsyncThunk(
  'task/actionPostTask',
  async (params: IParamsPostTask[], { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/task',
        method: 'POST',
        data: {
          data: params,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionGetTasks.fulfilled, (state, action) => {
      const tasks = get(action, 'payload.data');
      state.tasks = tasks.items.map((item: ITask) => ({
        ...item,
      }));
    });
  },
});

export const selectTasks = (state: RootState) => state.tasks.tasks;

export default slice.reducer;
